import React from "react";
import { NavLink } from "react-router-dom";
import { SocialIcon } from "react-social-icons";


export default function NavBar() {
    return (
        <header className="bg-blue-600">
            <div className="container mx-auto flex justify-between">
                <nav className="flex">
                    <NavLink 
                        to="/" 
                        exact
                        activeClassName="text-white" 
                        className="inflex-flex items-center py-6 px-3 mr-4 text-red-100 hover:text-black text-4xl font-bold cursive tracking-widest"
                    >
                        Dhruv
                    </NavLink>
                    <NavLink 
                        to="/post" 
                        className="inline-flex items-center py-3 px-3 my-6 rounded text-red-200 hover:text-black"
                        activeClassName="text-red-100 bg-blue-700"
                    >
                        Blog Post
                    </NavLink>
                    <NavLink 
                        to="/project" 
                        className="inline-flex items-center py-3 px-3 my-6 rounded text-red-200 hover:text-black"
                        activeClassName="text-red-100 bg-blue-700"
                    >
                        Projects
                    </NavLink>
                    <NavLink 
                        to="/about" 
                        className="inline-flex items-center py-3 px-3 my-6 rounded text-red-200 hover:text-black"
                        activeClassName="text-red-100 bg-blue-700"
                    >
                        About me!
                    </NavLink>
                </nav>
                <div className="inline-flex py-3 px-3 my-6" >
                    <SocialIcon url="https://github.com/Dhruv-Sachdev1313" className="mr-4" target="_blank" fgColor="#fff" style={{ height: 35, width: 35}} />
                    <SocialIcon url="https://twitter.com/dhruvhsachdev" className="mr-4" target="_blank" fgColor="#fff" style={{ height: 35, width: 35}} />
                    <SocialIcon url="https://www.linkedin.com/in/dhruv-sachdev-19b1b3143/" className="mr-4" target="_blank" fgColor="#fff" style={{ height: 35, width: 35}} />
                </div>
            </div>
        </header>
    )
}